@use '../styles/color';

.custom-radio {
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: 5px;

    input {
        opacity: 0;
        position: absolute;
    }

    .outer-circle {
        width: 20px;
        height: 20px;
        border: 2px solid color.$color-blue;
        border-radius: 50%;
    }

    .inner-circle {
        width: 10px;
        height: 10px;
        background-color: color.$color-blue;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: none;

        &.checked {
            display: block;
        }
    }
}