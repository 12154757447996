// Indroduction
// reference the color in the used file with @use '../..../assests/styles/color'
// then color.$xxx
// if you want a alpha channel use rgba($color, alpha)

$bg-color-basic: #f6f6f6;

$color-light-blue: #009fe3; //discover-color
$color-light-rose: #e6007e; //community-color
$color-purple: #951b81; //workspace-color
$color-blue: #452fd3;

$color-white-grey: #eeeeee; //white-grey
$color-white: #ffffff; //white
$color-black: #000000;

$error-color: #dc3545; //could also red
$error-color-2: #f00; //red

$success-color: #52c41a;

$behind-card-color: #fafafa;

$card-hover-color: #cad3ed;
$card-active-color: #d8dff4;
$card-bg-color-motoric: #ffecfe;
$card-bg-color-visual: #cbf3f0;

$card-box-shadow-color: #ccc;
$card-box-shadow-color-hover: #757575;

$input-field-hover-color: #cad3ed;
$input-field-active-color: #d8dff4;

$according-hover-color: #d8dff4;
$according-active-color: $color-light-rose;

$checkmark-disabled-color: #acb1be;

$pink-button-hover-color: #cb0d70;
$blue-button-hover-color: #3c3f87;
$white-button-hover-color: #e3e3e3;

$selection-purple: #6149cd;
$selection-purple-hover: #2C1693;

// motoric chip in community project
$motoric-chip-default-background-color: #fff5ff;
$motoric-chip-default-border-color: #fda9e5;
$motoric-chip-hover-color: #f9c8eb;
$motoric-chip-active-color: #ff67d4;

// visual chip in commuinity project
$visual-chip-default-background-color: #effffe;
$visual-chip-default-border-color: #92ded4;
$visual-chip-hover-color: #97e0d7;
$visual-chip-active-color: #19baa7;

// ImageDB chip in community project
$imgdb-chip-default-background-color: #e5ecfd;
$imgdb-chip-default-border-color: #aec1f2;
$imgdb-chip-hover-color: #c0d2ff;
$imgdb-chip-active-color: #8cacff;

// final custom color until used
// dont change anything in here.
// Change only in the scss which reference to this file
// you could define your custom-color here if you really need to.

$custom-color-black-0: #26272b;
$custom-color-black-1: #393c40;
$custom-color-black-2: #34393f;

$custom-color-blue-0: #000344;
$custom-color-blue-1: #00005c;
$custom-color-blue-2: #000a7c;
$custom-color-blue-3: #1247d0;
$custom-color-blue-4: #0000ff;
$custom-color-blue-5: #2288ee;
$custom-color-blue-6: #09e;
$custom-color-blue-7: #30a4f4;
$custom-color-blue-8: #169bd5;
$custom-color-blue-9: #00abe7;
$custom-color-blue-10: #4abdf3;
$custom-color-blue-11: #74bcec;
$custom-color-blue-12: #5f9ea0;
$custom-color-blue-13: #009de0;
$custom-color-blue-14: #8eddff;
$custom-color-blue-15: #2f61c3;
$custom-color-blue-16: #009fe1;
$custom-color-blue-17: #0F6C95;

$custom-color-72: #f8d3e7;
$custom-color-18: #f5c7cb;
$custom-color-20: #f8d6da;

$custom-color-81: #f08080;
$custom-color-106: #db99d0;
$custom-color-109: #fba3a3;

$custom-color-purple-0: #8a2be2;
$custom-color-purple-1: #8b41a8;
$custom-color-purple-2: #951b81;
$custom-color-purple-3: #ac98ef;
$custom-color-purple-4: #a33892;
$custom-color-purple-5: #e29dd7;

$custom-color-red-0: #731c25;
$custom-color-red-1: #f44336;
$custom-color-red-2: #c62828;
$custom-color-red-3: #b63b3b;
$custom-color-red-4: #dc3545;
$custom-color-red-5: #e20055;
$custom-color-red-6: #f04770;
$custom-color-red-7: #cd0e0e;
$custom-color-red-8: #e5007e;
$custom-color-red-9: #ff0000;
$custom-color-red-10: #e22e2e;
$custom-color-red-11: #fe0d0d;

$custom-color-green-0: #008000;
$custom-color-green-1: #093;
$custom-color-green-2: #3cb371;
$custom-color-green-3: #02cc15;
$custom-color-green-4: #91fa89;

$custom-color-yellow-0: #ffff00;
$custom-color-yellow-1: #f5e928;

//TODO Have to merge colors
$custom-color-53: #cccfce;
$custom-color-77: #c7c7c7;
$custom-color-78: #cfd3dd;
$custom-color-5: #cad3ed;
$custom-color-32: #c0c0c0;
$custom-color-90: #d1d7dd;
$custom-color-6: #d6d6d6;
$custom-color-9: #dedede;
$custom-color-12: #d8dff4;
$custom-color-13: #dddfe6;
$custom-color-29: #d1d3d1;
$custom-color-82: #d4d9de;
$custom-color-99: #c9baba;
$custom-color-95: #bdc1d1;
$custom-color-75: #e9eaf2;
$custom-color-76: #e6e7ed;
$custom-color-2: #ebecf0;
$custom-color-58: #e9eeff;
$custom-color-69: #ededed;
$custom-color-83: #fefeff;
$custom-color-92: #f0f0f0;
$custom-color-73: #fafbfc;
$custom-color-74: #f5f6fa;
$custom-color-48: #f3f6f8;
$custom-color-49: #fefefe;
$custom-color-7: #f2f2f2;
$custom-color-31: #f5f5f5;
$custom-color-51: #f7f8fa;
$custom-color-25: #d7d7d7;
$custom-color-26: #fdfdfd;
$custom-color-42: #f4f6f8;
$custom-color-45: #fff9f9;
$custom-color-101: #ecedf1;
$custom-color-104: #fafafc;
$custom-color-109: #e4e0e0;
$custom-color-110: #8c8c8c;
$custom-color-111: #474747;

$custom-color-62: #aaafbc;
$custom-color-63: #a8aebc;
$custom-color-64: #a7adbb;
$custom-color-19: #b2b7c3;
$custom-color-52: #8b8b8b;
$custom-color-61: #6c6c6c;
$custom-color-47: #4c4c4c;
$custom-color-50: #4b4b4b;
$custom-color-70: #4e4e4e;
$custom-color-88: #40485d;
$custom-color-89: #606a82;
$custom-color-65: #747883;
$custom-color-3: #757e95;
$custom-color-116: #767676;
$custom-color-8: #797979;
$custom-color-10: #929292;
$custom-color-22: #898f96;
$custom-color-23: #7f7f7f;
$custom-color-16: #6b6868;
$custom-color-28: #6b686a;
$custom-color-87: #6b6b6b;
$custom-color-39: #666;
$custom-color-40: #999;
$custom-color-103: #b1b5c6;
$custom-color-105: #7a7f85;
$custom-color-107: #e4e4e4;
$custom-color-108: #cdcdcd;
$custom-color-112: #b9c3e0;
$custom-color-113: #d5d2d2;
$custom-color-114: #968f8f;
$custom-color-115: #dfe1e8;

$custom-color-97: #0c0e0e;
$custom-color-100: #0d0e0f;
$custom-color-17: #333333;
$custom-color-38: #333;
$custom-color-102: #40464e;

$layer-dropout-border-color: #bec2ca;
$layer-dropout-plus-color: #11eb00;
