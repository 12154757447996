@use '../styles/color';
@use '../styles/font';
@use '../styles/variable';


.mat-expansion-panel-header {
    display: flex;
    flex-direction: row-reverse;
    height: 42px;
    background-color: transparent !important;
    padding: 0 20px;
    border-radius: 0 !important;

    @media screen and (max-width: 1550px) {
        padding: 0 14px;
    }

    &:hover {
        background-color: color.$custom-color-12 !important;
    }

    &.mat-expanded {
        height: 42px;

        .mat-expansion-panel-header-title a {
            color: color.$color-light-rose;
        }

        .mat-expansion-indicator {
            transform: rotate(90deg) !important;
        }
    }

    .mat-expansion-indicator {
        display: block;
        transform-origin: center;
        width: 10px;
        height: 5px;
        margin-left: -1px;

        &::after {
            content: none;
        }

        &::before {
            display: block;
            font-family: font.$material-icons;
            content: '\e5df';
            width: 20px;
            height: 100%;
            font-size: 20px;
            line-height: 5px;
            text-align: center;
            transform: translateX(-25%);
        }
    }
}
mat-accordion {
    border-radius: 0;
    max-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    mat-expansion-panel {
        background-color: transparent !important;
        border: none !important;
        box-shadow: none !important;
        border-radius: 0;
        margin: 0 !important;
        position: relative;

        &:first-child {
            .mat-expansion-panel-body {
                padding-bottom: 15% !important;
            }
        }

        &:last-child {
            height: calc(100% - 42px);

            .mat-expansion-panel-content {
                position: absolute;
                top: 42px;
                left: 0;
                width: 100%;
                height: calc(100% - 42px);

                .mat-expansion-panel-body {
                    padding-bottom: 13% !important;
                }

                &::after {
                    margin-top: -20px;
                }
            }
        }

        &.mat-expanded {
            border-radius: 0;

            &:last-child {
                height: calc(100% - 42px);

                .mat-expansion-panel-content {
                    position: absolute;
                    top: 42px;
                    left: 0;
                    width: 100%;
                    height: calc(100% - 42px);

                    .mat-expansion-panel-body {
                        padding-bottom: 13% !important;
                    }
                }
            }
        }

        mat-panel-title {
            margin-left: 9px;

            a {
                margin-top: 1px;
                font-size: 12px;
                line-height: 2;
                color: color.$color-black;
            }
        }

        .mat-expansion-panel-header {
            display: flex;
            flex-direction: row-reverse;
            height: 42px;
            padding: 0;
            background-color: transparent !important;
            padding: 0 20px;
            border-radius: 0 !important;

            @media screen and (max-width: 1550px) {
                padding: 0 14px;
            }

            &:hover {
                background-color: color.$custom-color-12 !important;
            }

            &.mat-expanded {
                height: 42px;

                .mat-expansion-panel-header-title a {
                    color: color.$color-light-rose;
                }

                .mat-expansion-indicator {
                    transform: rotate(90deg) !important;
                }
            }

            .mat-expansion-indicator {
                display: block;
                transform-origin: center;
                width: 10px;
                height: 5px;
                margin-left: -1px;

                &::after {
                    content: none;
                }

                &::before {
                    display: block;
                    font-family: font.$material-icons;
                    content: '\e5df';
                    width: 20px;
                    height: 100%;
                    font-size: 20px;
                    line-height: 5px;
                    text-align: center;
                    transform: translateX(-25%);
                }
            }
        }

        .mat-expansion-panel-content {
            position: relative;
            padding: 0 20px;

            &::after {
                content: '';
                width: calc(100% + 4px);
                height: 3px;
                border-radius: 1px;
                box-shadow: inset -2.4px -1.8px 3px 0
                    rgba(color.$color-white, 0.66),
                    inset 2.4px 1.8px 3px 0 color.$custom-color-95;
                background-color: color.$custom-color-2;
                margin: -10px -2px 0;
            }

            @media screen and (max-width: 1550px) {
                padding: 0 14px;
            }
        }
    }
}
.accordion {
    width: 310px;
    height: 37px;
    margin: 20px 33px 50px 20px;
    padding: 9px 10px 10px 11px;
    border-radius: 8px;
    text-align: left;
    box-shadow: variable.$box-shadow-2;
    background-blend-mode: soft-light ;
    background-image: variable.$std-gradient;
    &:hover {
        box-shadow: variable.$box-shadow-2;
        background-blend-mode: soft-light ;
        background-color: color.$according-hover-color;
        background-image: variable.$std-gradient;
    }
    &:active {
        box-shadow: variable.$box-shadow-2;
        background-blend-mode: soft-light ;
        color: color.$according-active-color;
        background-image: variable.$std-gradient;
    }
  }