// Custom Theming for Angular Material
@use 'assets/styles/color';
@use 'assets/styles/font';
@use 'assets/styles/variable';
@use '@angular/material' as mat;
@use 'assets/component/radiobutton';
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

@font-face {
    font-family: 'Museo';
    src: url(assets/font/Museo300-Regular.otf);
}

@font-face {
    font-family: 'Manrope';
    src: url(assets/font/Manrope-Regular.ttf);
}

@font-face {
    font-family: 'Manrope-Medium';
    src: url(assets/font/Manrope-Medium.ttf);
}

@font-face {
    font-family: 'Roboto';
    src: url(assets/font/Roboto-Regular.ttf);
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url(assets/font/Roboto-Bold.ttf);
}

@font-face {
    font-family: 'FiraSans';
    src: url(assets/font/FiraSans-Regular.ttf);
}

@font-face {
    font-family: 'FiraSans-SemiBold';
    src: url(assets/font/FiraSans-SemiBold.ttf);
}

@font-face {
    font-family: 'FiraCode-Regular';
    src: url(assets/font/FiraCode-Regular.ttf);
}

@font-face {
    font-family: 'NotoSans';
    src: url(assets/font/NotoSans-Regular.ttf);
}

@font-face {
    font-family: 'NotoSans-Bold';
    src: url(assets/font/NotoSans-Bold.ttf);
}

@font-face {
    font-family: 'NotoSans-Italic';
    src: url(assets/font/NotoSans-Italic.ttf);
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url(assets/font/Roboto-Regular.ttf);
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url(assets/font/Roboto-Medium.ttf);
}

@import 'assets/fontface/inter-fontface';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$lea-front-primary: mat.define-palette(mat.$indigo-palette);
$lea-front-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$lea-front-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$lea-front-theme: mat.define-light-theme(
        (
            color: (
                primary: $lea-front-primary,
                accent: $lea-front-accent,
                warn: $lea-front-warn,
            ),
        )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($lea-front-theme);

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

/* Bootstrap icons */
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css');

// style page: https://app.zeplin.io/project/60e56ec8b11a91ba7c58ecf8/screen/6184ff0192dce833769786ac
// Import all global styles
// css variables for colors, background-color, box-shadow

@import 'assets/component/accordion';
@import 'assets/component/button';
@import 'assets/component/card';
@import 'assets/component/checkbox-scene-composition';
@import 'assets/component/checkbox';
@import 'assets/component/dropdown';
@import 'assets/component/dropzone';
@import 'assets/component/input-field';
@import 'assets/component/cms';
@import 'assets/component/expert-toggle';

.p-carousel-item {
    opacity: 0;
}

.p-carousel-item-active {
    opacity: 1;
}

.p-carousel-items-content {
    padding: 15px;
}

.p-carousel-items-container {
    height: 370px;
    align-items: center;
    overflow: visible;
}

.add-scene-dialog .modal-dialog {
    margin-top: 300px;
}

.modal {
    z-index: 2000;
}

html,
body {
    height: 100%;
    margin: 0;
    font-family: font.$noto-sans-regular-font-family;

    &.no_scroll {
        overflow: hidden;
    }
}

.main-section {
    padding: 0 10% 0 10%;
}

/* Create Project Wizard Modal */
.add-project-wizard-modal {
    margin-top: 80px;
}

/* Card - Global */
.gallery-card-container {
    padding: 0 10px;
    transition: padding 0.4s ease;

    .row {
        margin-left: -7px;
        margin-right: -7px;
    }
}

@media (min-width: 1501px) {
    .gallery-card-container {
        padding: 0 15%;
    }
}

@media (min-width: 1350px) and (max-width: 1500px) {
    .gallery-card-container {
        padding: 0 5%;
    }
}

// hide slide number
.visually-hidden {
    display: none;
}

// bootstrap default label error class
label.is-invalid {
    color: color.$error-color;
}

/* For the search functionality in the scene composition */
.searched {
    background-color: rgba(color.$custom-color-purple-2, 0.3);
}

.searched-selected {
    background-color: color.$custom-color-yellow-0;
}

// ImageDB Styles

.ngx-file-drop__drop-zone {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100% !important;
    border: 0 !important;
    margin: 0 !important;
    border-radius: 5px !important;

    .ngx-file-drop__content {
        position: absolute;
        width: 100%;
        height: 100% !important;
        color: #000 !important;
    }

    &.ngx-file-drop__drop-zone--over {
        z-index: 10000;

        app-img-labeling-viewer {
            z-index: -1;
        }

        .ngx-file-drop__content {
            opacity: 0.3;
            z-index: 0;
        }
    }
}

.back-button {
    width: 25px;
    height: 25px;
    border-radius: 4px;
    box-shadow: -7.1px -7.1px 16px 0 #fafbfc, 0.8px 0.6px 5px 0 #bdc1d1,
    inset -0.7px -0.7px 16px 0 #f5f6fa, inset 0.7px 0.7px 16px 0 #e9eaf2;
    background-image: linear-gradient(to top, #e6e7ed, #f7f8fa);

    :hover {
        border-radius: 4px;
        background-blend-mode: soft-light;
        background-image: linear-gradient(to top, #7a7f85, #fff),
        linear-gradient(to bottom, #d8dff4, #d8dff4);
        cursor: pointer;
    }
}

.back-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: color.$color-black;
    border-radius: 4px;
    border: none;
    box-shadow: variable.$box-shadow;
    background-image: variable.$bg-img-gradient;

    &:hover {
        cursor: pointer;
        color: color.$color-black;
        box-shadow: variable.$box-shadow-3;
        background-blend-mode: soft-light;
        background-image: linear-gradient(
                to top,
                color.$custom-color-105,
                color.$color-white
        ),
        linear-gradient(
                to bottom,
                color.$custom-color-12,
                color.$custom-color-12
        );
    }

    .arrow-back-icon {
        font-size: 24px;
        font-weight: 400;
    }
}

#carousel {
    .p-carousel-items-container {
        //Default value for width is 33,33%
        > div {
            padding: 0 7px;
            max-width: 33.33%;
        }

        //If only one element is there, width is 100%
        .p-carousel-item:first-child:nth-last-child(1),
        .p-carousel-item:first-child:nth-last-child(1) ~ .p-carousel-item {
            max-width: 100%;
        }

        //If two elements are there, width is 50%
        .p-carousel-item:first-child:nth-last-child(2),
        .p-carousel-item:first-child:nth-last-child(2) ~ .p-carousel-item {
            max-width: 50%;
        }
    }

    button {
        color: color.$custom-color-10;

        .p-icon-wrapper {
            font-weight: bold;
        }

        svg {
            width: 20px;
            height: 31px;
        }

        &:hover {
            background: transparent;
            color: color.$color-blue;
        }

        &:focus {
            box-shadow: none;
        }
    }

    .p-disabled {
        display: none;
    }
}

// default scrollbar design for tryb
.tryb-scroll {
    &:hover {
        &::-webkit-scrollbar {
            visibility: visible;
        }

        &::-webkit-scrollbar-track {
            visibility: visible;
        }

        &::-webkit-scrollbar-thumb {
            visibility: visible;
        }
    }

    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        visibility: hidden;
    }

    &::-webkit-scrollbar-track {
        width: 1px;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
        background: color.$custom-color-78;
        background-clip: padding-box;
        visibility: hidden;
    }

    &::-webkit-scrollbar-thumb {
        width: 4px;
        background-color: color.$color-black;
        border-radius: 2px;
        visibility: hidden;
    }
}

.tryb-confirmation-dialog {
    .mat-mdc-dialog-container .mdc-dialog__surface {
        border-radius: 22px !important;
    }

    .dialog-wrapper {
        border-radius: 22px;
        padding: 35px 38px 40px 38px;
        font-family: font.$manrope_normal_font;
        font-size: 16px;

        .header {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            justify-content: center;

            p {
                font-family: font.$manrope_medium_font;
                font-size: 22px;
                margin: 0;
            }
        }

        .question-text {
            padding: 0;
            margin-top: 30px;

            p {
                color: #486788;
            }
        }

        .action-buttons {
            padding: 0;
            margin-top: 45px;
            display: flex;
            gap: 20px;
            justify-content: center;
            align-items: center;

            button {
                height: 40px;
                padding: 9px 20px;
                border-radius: 15px;
                color: #FFFFFF;
                border: none;
            }

            .confirm-button {
                background-color: #D30000;

                &:hover {
                    background-color: #A30D0D;
                }
            }

            .cancel-button {
                background-color: #6149CD;

                &:hover {
                    background-color: #2C1693;
                }
            }
        }
    }
}

.tryb-design-spinner {
    width: 100px;
    height: 100px;
    border: 6px solid #7A86A140;
    border-top: 6px solid color.$selection-purple;
    border-radius: 50%;
    animation: spin 0.8s linear infinite;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
