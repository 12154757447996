// cms-page
.cms-page {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #4a545b;

    h1,h2,h3,h4,h5,h6 {
        margin-top: 0;
        margin-bottom: 10px;
    }

    .h3,.h4,.h5,.h6,h3,h4,h5,h6 {
        line-height: 1.2;
        font-weight: 500;
    }

    .h1,h1 {
        font-weight: 700;
        font-size: 2.25rem;
    }

    .h2,h2 {
        font-weight: 700;
        line-height: 34px;
        font-size: 1.75rem;
    }

    @media (max-width: 1200px) {
        h1,.h1 {
            font-size:calc(1.35rem + 1.2vw);
        }
    }

    @media (max-width: 1200px) {
        h2,.h2 {
            font-size:calc(1.3rem + .6vw);
        }
    }

    h3,.h3 {
        font-size: 1.5rem;
    }

    @media (max-width: 1200px) {
        h3,.h3 {
            font-size:calc(1.275rem + .3vw);
        }
    }

    h4,.h4 {
        font-size: 1.25rem;
    }

    h5,.h5 {
        font-size: 1rem;
    }

    h6,.h6 {
        font-size: .875rem;
    }

    p {
        margin-top: 0;
        margin-bottom: 16px;
    }

    .row {
        margin-right: -20px;
        margin-left: -20px;
    }

    .container,
    .cms-section .boxed {
        max-width: 1400px;
        width: 100%;
        padding-right: 20px;
        padding-left: 20px;
        margin-right: auto;
        margin-left: auto;
    }

    .cms-section {
        overflow: hidden;

        .cms-section-sidebar {
            display: flex;
        }
    }

    .cms-block {
        overflow: hidden;

        .cms-block-container .cms-block-container-row {
            flex-direction: row;

            &.has--sidebar {
                flex-direction: column;

                 >div {
                     flex-basis: auto;
                     -ms-flex-positive: 1;
                     flex-grow: 1;
                     max-width: 100%;
                 }
            }
        }

        .cms-block-background {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: none;
        }

        &.bg-image {
            position: relative;
            background-repeat: no-repeat;
            background-position: 50%;
        }
    }
}


//cms-element-image
.cms-element-image {
    position: relative;
    height: 100%;

    .cms-image {
        display: block;
        max-width: 100%;
    }

    .cms-image-container {
        display: block;
        max-width: 100%;
        position: relative;

        &.is-cover {
            object-fit: contain;
            height: 100%;

            .cms-image {
                object-fit: cover;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }
        }

        &.is-cover,
        &.is-stretch {
            .cms-image {
                width: 100%;
            }
        }
    }

    .cms-image-link {
        display: block;
    }
}


//cms-element-text
.cms-element-text,
.cms-element-image {
    word-break: break-word;

    &.has-vertical-alignment {
        display: flex;
        height: 100%;
    }
}

.cms-block-center-text {
    .cms-element-text {
        padding: 30px;
    }
}
