@use 'color';

$menu-height: 80px;
$header-box-height: 76px;

$box-shadow: -7.1px -7.1px 16px 0 color.$custom-color-73,
1.6px 1.2px 10px 0 rgba(color.$custom-color-95, 0.5),
inset -0.7px -0.7px 16px 0 color.$custom-color-74,
inset 0.7px 0.7px 16px 0 color.$custom-color-75;

$box-shadow-2: -7.1px -7.1px 16px 0 color.$custom-color-73,
1.6px 1.2px 5px 0 rgba(color.$custom-color-95, 0.75), //different row
inset -0.7px -0.7px 16px 0 color.$custom-color-74,
inset 0.7px 0.7px 16px 0 color.$custom-color-75;

$box-shadow-3: -2.2px -3.3px 8px 0 color.$custom-color-104,
0.7px 0.7px 5px 0 color.$custom-color-103,
inset 0.7px 0.7px 3px 0 color.$custom-color-2;

$bg-img-gradient-hover: linear-gradient(-37deg, color.$custom-color-105, color.$color-white), linear-gradient(to bottom, color.$card-active-color, color.$card-active-color);
$box-shadow-4: -7.1px -7.1px 16px 0 color.$custom-color-73,
1.6px 1.2px 10px 0 color.$custom-color-95,
inset -0.7px -0.7px 16px 0 color.$custom-color-74,
inset 0.7px 0.7px 16px 0 color.$custom-color-75;

$box-shadow-5: 0.7px 0.7px 7px 0 color.$custom-color-2, inset -3.1px -2.5px 4px 0 rgba(250, 251, 252, 0.5), inset 2.8px 2.8px 5px 0 rgba(189, 193, 209, 0.15);

$std-gradient: linear-gradient(to top, color.$custom-color-76, color.$custom-color-51);

$bg-img-gradient: linear-gradient(-37deg, color.$custom-color-51, color.$custom-color-76);
$bg-img-gradient-btn: linear-gradient(to top, color.$custom-color-105,
    color.$color-white), linear-gradient(to bottom,
    color.$custom-color-12, color.$custom-color-12);
$bg-img-gradient-reverse: linear-gradient(-37deg, color.$custom-color-76, color.$custom-color-51);
$bg-training-gradient: linear-gradient(to bottom,color.$color-white, color.$custom-color-107);
// HEADER & MENU
$bg-header-img: linear-gradient(to right, color.$custom-color-black-0, color.$custom-color-black-1);
$bg-header-img-btn: linear-gradient(to right, color.$custom-color-black-0, color.$custom-color-black-2);
$bg-menu-items-img: linear-gradient(to right, rgba(color.$custom-color-black-0, 0.9), rgba(color.$custom-color-black-1, 0.9));
$box-shadow-header: -4.9px -4.9px 20px 0 color.$custom-color-102, 4px 3px 19px 0 rgba(color.$custom-color-100, 0.5);

$box-shadow-dark-btn: 0.7px 0.7px 5px 0 color.$custom-color-100;
$box-shadow-dark-btn-hover: 0.7px 0.7px 5px 0 color.$card-active-color;

// Skill architecure
$active-layer-drop-shadow: drop-shadow(0 0 10px rgba(color.$custom-color-95, 0.75));

@mixin span-overflow-ellipsis-nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
}
