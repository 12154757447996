@use '../styles/font';
@use '../styles/variable';
@use '../styles/color';


.tryb-checkbox {
    display: flex;
    align-items: center;
    height: 32px;
    position: relative;
    padding-left: 35px;
    margin-bottom: 0;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    & input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    & .checkmark {
        position: absolute;
        left: 0;
        width: 32px;
        height: 32px;
        border-radius: 8px;
        box-shadow: 0.7px 0.7px 7px 0 color.$custom-color-2,
            inset -3.1px -2.5px 4px 0 rgba(color.$custom-color-73, 0.65),
            inset 2.8px 2.8px 5px 0 rgba(color.$custom-color-95, 0.25)
            ;
        background-color: color.$custom-color-2;
        border: 1px solid transparent;
        transition: border-color 0.4s ease-in-out;
        color: color.$layer-dropout-plus-color;
    }

    & .disabled {
        color: color.$checkmark-disabled-color;
    }

    &:hover input:not(:disabled) + .checkmark {
        border-color: color.$custom-color-5;
    }

    & input:checked ~ .checkmark::after{
        font-family: 'Material Icons', sans-serif;
        content: '\e2e6';
        font-size: 22px;
        margin-left: 3px;
    }
}

@keyframes checkmark {
    0% {
        height: 0;
        width: 0;
        opacity: 1;
    }
    20% {
        height: 0;
        width: 7px;
        opacity: 1;
    }
    40% {
        height: 15px;
        width: 7px;
        opacity: 1;
    }
    100% {
        height: 15px;
        width: 7px;
        opacity: 1;
    }
}
