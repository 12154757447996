@use 'color';

$noto-sans-font: 'Noto sans';
$noto-sans-font-family: 'Noto Sans', sans-serif;
$noto-sans-italic-font-family: "Noto Sans Italic", "Noto Sans", sans-serif;
$italic: italic;
$firasans-regular-font-family: 'Fira Sans Regular', 'Noto Sans', 'sans-serif';
$firacode-font-family: 'FiraCode-Regular';
$regular-font-family: 'Regular', 'Noto Sans', 'sans-serif';

//'NotoSans-Bold', 'NotoSans-Regular', 'Roboto', sans-serif
$noto-sans-bold-font-family: 'NotoSans-Bold', 'sans-serif';
$noto-sans-regular-font-family: 'NotoSans', 'sans-serif';

$roboto-font-family: 'Roboto', 'sans-serif';
$roboto-medium-font-family: 'Roboto-Medium', 'sans-serif';
$roboto-regular-font-family: 'Roboto-Regular', 'sans-serif';
$roboto-lightitalic-font-family: 'Roboto-LightItalic', 'sans-serif';
$roboto-bold-font-family: 'Roboto-Bold', 'sans-serif';

$firasans-font-family: 'FiraSans', 'sans-serif';
$firasans-semibold-font-family: 'FiraSans-SemiBold', 'sans-serif';

$arial-font-family: 'Arial Regular', 'Arial', 'sans-serif';
$inter-font-family: 'Inter', 'sans-serif';
$museo-font-family: 'Museo', 'sans-serif';

$manrope_normal_font: 'Manrope', 'sans-serif';
$manrope_medium_font: 'Manrope-Medium', 'sans-serif';
$manrope_regular_font: 'Manrope-Regular', 'Manrope', 'sans-serif';

$material-icons: 'Material Icons';

@mixin font-style-title-text {
    font-family: $noto-sans-font-family;
    font-weight: bold;
    font-size: 16px;
    color: color.$color-black;
}

@mixin font-style-h1-text {
    font-family: $firasans-regular-font-family;
    font-weight: bold;
    font-size: 35px;
    line-height: 44px;
    color: color.$color-black;
}

@mixin font-style-navigation-text {
    font-family: $noto-sans-font-family;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: color.$color-black;
}

@mixin font-style-body-text {
    font-family: $regular-font-family;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: color.$color-black;
}

@mixin font-imgDb-workspace-noto-sans {
    font-family: $noto-sans-font;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: color.$color-black;
}
