@use '../styles/color';
@use '../styles/variable';

.btn {
    &.btn-tryb-pink {
        background-color: color.$color-light-rose;
        color: color.$color-white;
        border-radius: 0.438rem;

        &:hover {
            background-color: color.$pink-button-hover-color;
        }
    }

    &.btn-tryb-blue {
        background-color: color.$color-blue;
        color: color.$color-white;
        border-radius: 0.438rem;

        &:hover {
            background-color: color.$blue-button-hover-color;
        }
    }

    &.btn-tryb-ice-blue {
        background-color: color.$custom-color-blue-8;
        color: color.$color-white;
        border-radius: 0.438rem;

        &:hover {
            background-color: color.$custom-color-blue-17;
        }
    }

    &.btn-tryb-ice-blue-outlined {
        border: 2px solid color.$custom-color-blue-8;
        background-color: transparent;
        color: color.$custom-color-blue-8;
        border-radius: 0.438rem;

        &:hover {
            color: color.$custom-color-blue-17;
            border-color: color.$custom-color-blue-17;
        }
    }

    &.btn-tryb-white {
        background-color: color.$color-white;
        color: color.$color-blue;
        border-radius: 0.438rem;

        &.btn-outline {
            border: solid 2px color.$color-blue;
        }

        &:hover {
            background-color: color.$white-button-hover-color;
        }
    }
    
    &.btn-tryb-gray {
        box-shadow: variable.$box-shadow;
        background-image: variable.$bg-img-gradient;
        color: color.$color-black;
        border: none;
        border-radius: 4px;

        &:hover {
            box-shadow: variable.$box-shadow-3;
            background-blend-mode: soft-light;
            background-image: variable.$bg-img-gradient-btn;
        }
    }

    &.btn-tryb-dark {
        background-image: variable.$bg-header-img-btn;
        box-shadow: variable.$box-shadow-dark-btn;
        border-radius: 4px;
        color: color.$color-white;
        transition: box-shadow .4s ease;

        &:hover {
            box-shadow: variable.$box-shadow-dark-btn-hover;
        }
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }
}
