@use '../styles/color';
@use '../styles/font';

$mat-box-shadow: 0.7px 0.7px 7px 0 color.$custom-color-2,
    inset -3.1px -2.5px 4px 0 rgba(color.$custom-color-73, 0.5),
    inset 2.8px 2.8px 5px 0 rgba(color.$custom-color-95, 0.3);

    
.expert-toggle .mat-slide-toggle  {
    .mat-slide-toggle-bar {
        width: 60px;
        height: 30px;
        border-radius: 15px;
        box-shadow: $mat-box-shadow;
        background-color: color.$custom-color-2;
    }
    .mat-slide-toggle-thumb-container {
        width: 22px;
        height: 22px;
        top: 5px;
        left: 4px;

        .mat-slide-toggle-thumb {
            background-color: color.$custom-color-3;
        }

        .mat-ripple-element {
            background-color: color.$custom-color-3;
        }
    }

    &.mat-checked {
        .mat-slide-toggle-thumb-container {
            transform: translate3d(30px, 0, 0) !important;

            .mat-slide-toggle-thumb {
                background-color: color.$custom-color-green-3;
            }

            .mat-ripple-element {
                background-color: color.$custom-color-green-3;
            }
        }

        .mat-slide-toggle-bar {
            box-shadow: $mat-box-shadow;
            background-color: color.$custom-color-2;
        }
    }
}

.slide-toggle {
    display: flex;
    position: absolute;
    right: 12px;
    z-index: 100;

    .slide-toggle-title {
        font-family: font.$noto-sans-bold-font-family;
        font-size: 12px;
        margin-bottom: 0;
        margin-top: 3px;
        text-transform: uppercase;
    }
}
