@use '../styles/color';

.card {
    border: 0;
    box-shadow: 3px 3px 4px rgba(color.$custom-color-97, 0.25);
    border-radius: 4px;
    transition: box-shadow 0.5s ease;

    .delete-button {
        opacity: 0;
        position: absolute;
        cursor: pointer;
        top: 10px;
        right: 10px;
        border-radius: 50%;
        background-color: color.$custom-color-red-1;
        color: color.$color-white;
        width: 35px;
        height: 35px;
        transition: all 0.4s ease;

        .material-icons {
            margin-top: 5px;
            margin-left: 5px;
            font-size: 25px;
        }
        // delete button hover effect
        &:hover {
            background-color: color.$custom-color-red-2;
        }
    }

    // card hover effect
    &:hover {
        box-shadow: 3px 3px 8px rgba(color.$custom-color-97, 0.5);

        .delete-button {
            opacity: 1;
        }
    }
    &:active {
        box-shadow: 3px 3px 8px rgba(color.$custom-color-97, 0.5);
        background-color: color.$card-active-color;
    }
    .card-img-top {
        width: 100%;
        cursor: pointer;
    }

    .card-footer {
        background-color: color.$color-white;
        padding: 8px 10px 9px;
        border-color: color.$custom-color-9;
        color: color.$custom-color-8;
        .user-profile-img {
            img {
                cursor: pointer;
                width: 30px;
                height: 30px;
                margin-right: 9px;
                background-color: color.$color-white;
                border-radius: 50%;
            }
        }

        .project-name,
        .add-new-card-title {
            color: color.$custom-color-8;
            margin-top: 7px;
            margin-bottom: 0px;
            font-size: 13px;

            &:hover {
                text-decoration: none;
            }
        }

        .project-name {
            cursor: pointer;
        }

        .left-side {
            width: auto;
        }

        .right-side {
            margin-top: 4px;
            float: right;

            .icon-container {
                cursor: pointer;
                color: color.$custom-color-16;
                float: left;

                .material-icons {
                    font-size: 20px;
                }
            }
            .clock {
                margin-right: 10px;
            }

            .person {
                margin-right: 10px;
            }

            .icon-count-text {
                margin: 2px 0 0 3px;
                font-size: small;
                color: color.$custom-color-8;
                text-align: left;
            }
        }

        @media (max-width: 655px) and (min-width: 576px) {
            .left-side {
                height: 30px;
            }

            .right-side {
                margin-top: 6px;
                height: 30px;
                float: left;
            }
        }

        @media (max-width: 340px) {
            .left-side {
                height: 30px;
            }

            .right-side {
                margin-top: 8px;
                height: 30px;
                float: left;
            }
        }
    }
}
