@use '../styles/font';
@use '../styles/color';
@use '../styles/variable';



.tryb-dropzone {
    font-family: font.$noto-sans-bold-font-family;
    width: 90%;
    text-align: center;
    border-radius: 8px;
    border: 2px dashed color.$custom-color-78;
    background-color: color.$custom-color-2;
    padding: 20px 12px;
    line-height: 1;
    font-size: 16px;
    max-width: 300px;
    margin-right: 10%;
    color: color.$custom-color-19 !important;

    p {
        color: color.$custom-color-19 !important;
        line-height: 1;
        font-size: 16px !important;
        white-space: normal !important;
        width: 100% !important;
        text-align: center !important;
    }
}
