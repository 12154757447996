@use '../styles/font';
@use '../styles/variable';
@use '../styles/color';

.tryb-dropdown {
    position: relative;
    border-radius: 8px;
    padding: 9px 15px;
    width: 200px;
    height: 30px;
    box-shadow: variable.$box-shadow-2;
    background-blend-mode: soft-light, ;
    background-image: linear-gradient(to top, color.$custom-color-88, color.$custom-color-89), linear-gradient(to bottom, color.$custom-color-2, color.$custom-color-2);

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 15px;
        transform-origin: center;
        transform: translate(50%, -50%) rotate(90deg);
        width: 15px;
        height: 15px;
        background-image: url(../svg/algorithm/stepper-arrow.png);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        pointer-events: none;
    }

    &.disabled:after,
    &>select:disabled {
        opacity: 0.3;
    }

    &.pe-none {
        pointer-events: none;
    }

    select {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        appearance: none;
        outline: none;
        color: color.$color-black;
        padding: 0 15px;
        border-radius: 8px;
        border: none;
        font-size: 12px;
        background-color: transparent;

        &:hover {
            &:not(:disabled):not(.disabled) {
                box-shadow: variable.$box-shadow-2;
                background-blend-mode: soft-light, ;
                background-image: linear-gradient(to top, color.$custom-color-88, color.$custom-color-89), linear-gradient(to bottom, color.$custom-color-12, color.$custom-color-12);
            }
        } 
    }
}