@use '../styles/font';
@use '../styles/color';


@mixin input-widths {
    width: 200px;
    @media (max-width: 119.8px) and (min-width: 992px) {
        text-align: left;
        width: 150px;
    }
    @media (max-width: 991.8px) {
        text-align: left;
        width: 130px;
    }
}

.tryb-input-container {

    .input-label {
        font-family: font.$noto-sans-bold-font-family;
        font-size: 12px;
        margin-bottom: 10px;
    }

    input,
    select {
        outline: none;
        font-size: 12px;
        text-align: right;
        @include input-widths;
        box-shadow: 0.7px 0.7px 7px 0 color.$custom-color-2,
        inset -3.1px -2.5px 4px 0 rgba(color.$custom-color-73, 0.5),
        inset 2.8px 2.8px 5px 0 rgba(color.$custom-color-95, 0.15);
        background-color: color.$custom-color-2;
        height: 30px;
        padding-right: 15px;
        border: 2px solid transparent;
        border-radius: 8px;
        transition: border-color 0.4s ease-in-out;

        &.focused:not(:disabled),
        &:active:not(:disabled),
        &:focus:not(:disabled) {
            background-image: none;
            background-color: color.$custom-color-12;
        }

        &:hover:not(:disabled) {
            border-color: color.$custom-color-5;
        }

        &:disabled {
            color: color.$custom-color-62;
        }

        &.is-invalid {
            box-shadow: 0 0 0 2px rgba(color.$custom-color-red-4, 0.25);
            background-image: (
                "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e"
            );
            background-repeat: no-repeat;
            background-position: right calc(0.375em + 0.1875rem) center;
            background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
        }
    }

    input {
        @media (min-width: 992px) {
            &.has-steppers {
                padding-right: 35px;
            }
        }
    }

    select {
        text-align: left;
        color: color.$color-black;
    }

    .steppers {
        top: 50%;
        right: 12px;
        transform: translateY(-50%);

        .stepper {
            padding: 2px 6px;
            cursor: pointer;

            &.up {
                img {
                    width: 10px;
                    height: 10px;
                    transform: rotate(-90deg);
                }
            }

            &.down {
                img {
                    width: 10px;
                    height: 10px;
                    transform: rotate(90deg);
                }
            }
        }
    }

    input:disabled + .steppers {
        opacity: 0.6;
        pointer-events: none;
    }

    .input-wrapper {
        position: relative;

        input {
            padding-left: calc(38px + 0.75rem);
        }

        .fixed-prefix {
            position: absolute;
            font-size: 12px;
            top: 1px;
            left: 1px;
            width: 38px;
            height: calc(100% - 2px);
            background-color: color.$custom-color-13;
            text-align: center;
            vertical-align: middle;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            z-index: 1;
            pointer-events: none;
        }

        &.disabled {
            .fixed-prefix {
                opacity: 0.6;
            }
        }
    }

    .alert {
        font-size: 12px;
        z-index: 2;
    }
}
