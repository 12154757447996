@use '../styles/font';
@use '../styles/variable';
@use '../styles/color';


.checkbox-scene-composition {
    width: 16px;
    height: 16px;
    border-radius: 2px;
    border: solid 1px color.$custom-color-64;
    background-color: color.$custom-color-51;
    position: relative;
    cursor: pointer;

    &:hover {
        border-color: color.$color-light-rose;

        .info-text {
            display: block;
        }
    }
    & input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    & .checkmark {
        position: absolute;
        top: 5px;
        left: 0;
        width: 32px;
        height: 32px;
        border-radius: 8px;
        box-shadow: 0.7px 0.7px 7px 0 color.$custom-color-2,
            inset -3.1px -2.5px 4px 0 color.$custom-color-73,
            inset 2.8px 2.8px 5px 0 color.$custom-color-95
            ;
        background-color: color.$custom-color-2;
        border: 1px solid transparent;
        transition: border-color 0.4s ease-in-out;

        &:after,
        &:before {
            content: '';
            position: absolute;
            display: none;
        }
    }

    input {
        display: none;
    }

    .mark {
        display: none;
        width: 9px;
        height: 9px;
        background-color: color.$color-light-rose;
        border-radius: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }

    input:checked ~ .mark {
        display: block;
    }
}


.disable-fixation {
    .checkbox-scene-composition {
        border-color: color.$custom-color-64;

        &:hover {
            border-color: color.$custom-color-64;
        }
    }

    .mark {
        background-color: color.$custom-color-64;
    }

    .info-text {
        color: color.$custom-color-65;
        border: solid 1px color.$custom-color-64;
        background-color: color.$custom-color-2;
    }
}